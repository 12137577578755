#LandingTitle {
  font-size: 1.5rem;
  margin-top: 2rem;
}

#PayOptions {
  --card-width: 19em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 1.5rem 0;
  margin-inline: -1.5rem;
  column-gap: 1.5rem;
  @media screen and (min-width: 964px) {
    column-gap: 3.5rem;
  }
  @media screen and (max-width: 576px) {
    margin-inline: -1rem;
  }

  .payOption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: var(--card-width);
    margin-bottom: 1.5rem;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 850px) {
      width: auto;
      flex: 0.6;
      min-width: 18em;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
      margin-inline: 0;
      min-width: 100%;
    }

    & > svg {
      color: var(--icon-colour);
      font-size: 2.5em;
      margin-bottom: 1rem;
    }
    h2 {
      font-size: 1.2em;
      margin-block: 0;
    }
    p {
      margin-top: 0.3em;
      margin-bottom: 0;
      font-size: 0.9rem;
      color: #000000CC;
    }
  }
  button {
    width: 100%;
    padding-inline: 10px;
    font-size: 1rem;
    margin-top: 1rem;
  }
}
