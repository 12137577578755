#PayOptions .payOption,
div#ScanQR {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25em;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(163, 172, 191, 0.50);
}

.linkBtn {
  display: inline-block;
  border: none;
  background: none;
  font-weight: 500;
  font-family: Rubik;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  font-size: 0.9em;
  &:hover {
    text-decoration: underline;
  }
}

#CancelPayment {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

#PaymentExpired {
  h2 {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 0;
  }
  h2 + p {
    margin-top: 0.2em;
  }
  a:not(.btn) {
    color: var(--psi-blue);
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

#PaymentCancelled {
  p {
    margin: 0;
  }
  ol {
    margin-top: 0;
  }
}

#PaymentRejected {
  p {
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
  }
}
