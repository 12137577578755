.iconTextCont {
  text-align: center;
  --icon-size: 6rem;

  & > *:first-child {
    margin-top: 2rem;
  }
  
  .fa-stack {
    width: var(--icon-size);
    height: var(--icon-size);
    margin-top: 0.7rem;
    .fa-spinner-third {
      color: rgba(0, 0, 0, 0.6);
      height: var(--icon-size);
      width: var(--icon-size);
    }
    svg:last-child {
      height: calc(var(--icon-size) * 0.4);
      width: calc((var(--icon-size) * 0.5));
    }

    & + button { // pause/resume button
      display: block;
      background: none;
      border: none;
      border-radius: 0.2rem;
      padding: 0.5rem;
      margin: -0.2rem auto -0.5rem auto;
      min-width: 3rem;
      height: 2rem;
      cursor: pointer;

      // prevent spinning svg from overlapping the button
      position: relative;
      z-index: 1;
    }
  }
  svg:last-of-type {
    color: var(--icon-colour);
  }
  &.green svg:last-of-type {
    color: #97C93D;
  }

  h1 {
    font-size: 1.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  hr + h2 {
    font-size: 1.3em;
    margin-top: 2rem;
    margin-bottom: 0.5em;
  }
  
  p {
    margin-top: 0;
    color: #000000CC;
  }
}
