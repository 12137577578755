:root {
  --interac-yellow: #FFB92A;
  --interac-yellow-border: #B2811D;
  --interac-yellow-hover: hsl(40.28deg 100% 63%);
  --psi-blue: #00444D;
  --icon-colour: #000000;
  --grey-colour: #F8F9FA;
}

body {
  margin: 0;
  font-family: Rubik, sans-serif;
}

h1, h2, h3 {
  font-weight: 400;
}

#container {
  --padding-inline: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  @media screen and (min-width: 768px) {
    --padding-inline: 2rem;
    margin: 0 auto;
    padding: 2rem 2rem;
    min-height: calc(100vh - 4rem); // fallback
    min-height: calc(100dvh - 4rem);

    & > * {
      border: 1px solid #cccccc;
      border-bottom: none !important;
    }

    & > *:first-child {
      border-radius: 5px 5px 0 0;
    }

    & > *:last-child {
      border-width: 0;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
    }
  }
  @media screen and (max-width: 767.9px) {
    min-height: 100vh; // fallback
    min-height: 100dvh;
  }

  main {
    overflow: hidden;
    --border-height: 1.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

#content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 3rem;
  
  @media screen and (max-width: 576px) {
    padding: 1rem 1rem;
  }
}

.btn {
  border: none;
  background: var(--interac-yellow);
  color: #000000;
  text-align: center;
  box-sizing: border-box;
  font-weight: 500;
  font-family: Rubik;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  padding: 0.5em 1.4em;
  border-radius: 6px;
  &:hover {
    background: var(--interac-yellow-hover);
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.7;
  }
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
