button#ChangeDevice {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

#Help {
  margin-top: -0.5em;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  a {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

div#ScanQR {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  h2 {
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0;
  }
  img {
    max-width: 200px;
  }
}

hr {
  width: 100%;
  border: 0;
  border-top: 1px solid #cccccc;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 40px;
}
