@use "sass:math" as math;
$deg: 25deg;

#ProgressBar {
  display: flex;
  padding: 1rem 5px;
  --angle: #{$deg};
  --border-radius-1: 30px;
  --border-radius-2: 50px;
  
  ol {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
    width: 700px;
    min-height: 3rem;
    --padding-x: 1rem;
    --border-colour: rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 767.9px) {
      width: calc(100% - 4px);
    }
  
    li {
      /* bf active */
      --bg-colour: white;
      --prev-bg-colour: white;
      --prev-border-colour: #ADAEAF;
      &.active { /* active */
        --bg-colour: var(--interac-yellow);
        --prev-bg-colour: white;
      }
      &.active ~ li { /* after active */
        --bg-colour: var(--grey-colour);
        --prev-bg-colour: var(--grey-colour);
      }
      &.active + li { /* immediately after active */
        --prev-bg-colour: var(--interac-yellow);
        --prev-border-colour: var(--interac-yellow-border);
      }

      list-style-type: none;
      display: inline-flex;
      justify-content: center;
      flex: 1 1 0;
      position: relative;
      border-block: 1px solid var(--border-colour);
      box-sizing: border-box;
      background-color: var(--bg-colour);
      padding-block: 0.5rem;
      overflow: hidden;
      font-size: 0.9em;
      &:first-child {
        border-left: 1px solid var(--border-colour);
        border-radius: var(--border-radius-1) 0 0 var(--border-radius-1) / var(--border-radius-2) 0 0 var(--border-radius-2);
        span {
          margin-left: var(--padding-x);
        }
      }
      &:last-child {
        border-right: 1px solid var(--border-colour);
        border-radius: 0 var(--border-radius-1) var(--border-radius-1) 0 / 0 var(--border-radius-2) var(--border-radius-2) 0;
        flex-grow: 0.9;
      }

      &:not(:first-child) {
        padding-left: 2rem;
        &::before, &::after { // triangles between progress bar steps
          --rotate: calc(45deg + var(--angle) / 2); // put rhombus upright
          --scaleY: #{math.cos($deg)}; // fallback
          --scaleY: cos(var(--angle)); // compensate for the skewX to make a perfect rhombus instead of a parallelogram
          --scale: #{math.div(0.5, math.sin(45deg + math.div($deg, 2)))}; // fallback
          --scale: calc(0.5 / sin(45deg + var(--angle) / 2)); // scale down to match height of progress bar
          content: '';
          position: absolute;
          z-index: 1;
          height: 100%;
          aspect-ratio: 1/1;
          left: 0;
          top: 0;
          transform-origin: center;
          transform: translateX(-50%) rotate(var(--rotate)) skewX(var(--angle)) scaleY(var(--scaleY)) scale(var(--scale));
        }
        &::before { // triangle border
          --scale: #{math.div(0.54, math.sin(45deg + math.div($deg, 2)))}; // fallback
          --scale: calc(0.54 / sin(45deg + var(--angle) / 2)); // make border triangle slightly larger
          background-color: var(--prev-border-colour);
        }
        &::after {
          background-color: var(--prev-bg-colour);
        }
        span {
          margin-right: var(--padding-x);
        }
      }

      span {
        z-index: 10;
        display: flex;
        align-self: center;
        font-size: 0.9em;
      }

      @media screen and (max-width: 425px) {
        --angle: 45deg;
        &:not(:first-child) {
          padding-left: 1.3rem;
        }
        span {
          font-size: 0.8em;
        }
      }
    }
  }
}
