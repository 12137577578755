#Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh; // fallback
  height: 100dvh;
  text-align: center;
  padding: 3rem 1rem;
  box-sizing: border-box;
  gap: 0.5rem;

  svg {
    --fa-primary-color: #7F7F7F;
    --fa-secondary-color: #CCCCCC;
    --fa-secondary-opacity: 1;
  }

  h1 {
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin: 0 0 2rem;
  }
}
