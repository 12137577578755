#InfoBlock {
  position: relative;
  background: var(--grey-colour);
  padding: 1rem var(--padding-inline);
  text-align: left;
  font-size: 0.9rem;

  * {
    text-align: left;
  }

  h2 {
    margin: 0;
    font-size: 1.5rem;
    display: inline;
  }

  h2 + p {
    margin-top: 0.8rem;
  }
  
  &.collapsible {
    *[role='button'] {
      padding: 1rem var(--padding-inline);
      margin: -1rem calc(-1 * var(--padding-inline));
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.expanded {
      *[role='button'] {
        margin-bottom: 0;
      }
      p:first-of-type {
        margin-top: 0;
      }
    }
    svg {
      height: 1.5rem;
      color: var(--psi-blue);
      margin-bottom: 5px;
    }
  }

  p:nth-of-type(2),
  p:last-of-type {
    margin-bottom: 0;
  }

  ol {
    margin: 0;
    padding-left: 30px;
  }
}

#Limits {
  background: var(--grey-colour);
  position: relative;
  padding: 1rem var(--padding-inline);
  font-size: 0.9rem;

  h3 {
    font-size: 1.1rem;
    margin: 0;
  }

  p {
    margin: 0.2rem 0 0 0;

    a {
      color: var(--psi-blue);
      text-decoration: none;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#InfoBlock, #Limits {
  border-bottom: 1px solid #cccccc;
}