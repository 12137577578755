footer {
  text-align: center;

  a, button {
    text-decoration: none;
    color: white;
    font-family: inherit;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  & > *:first-child {
    background: #222222;
    margin: 0;
    padding: 0.7rem;
    color: white;
    font-size: 0.75rem;
  }

  & > *:last-child {
    background: #000000;
    padding-block: 0 0.7rem;
    padding-inline: 1rem;

    #links {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
      flex-wrap: wrap;

      a, button {
        display: inline-block;
        padding: 0.3rem;
        font-size: 0.7rem;
      }

      & > *:not(:last-child)::after {
        content: '•';
        margin-inline: 0.5rem;
        color: white;
        font-family: inherit;
        font-weight: 500;
        font-size: 0.8rem;
      }
    }

    #disclaimer {
      p {
        color: white;
        opacity: 0.8;
        margin: 0;
        font-size: 0.7rem;
        font-weight: 300;
        line-height: 1rem;
      }
  
      p + p {
        margin-top: 3px;
      }
    }
  }

  @media screen and (max-width: 767.9px) {
    & > *:first-child {
      padding-inline: var(--padding-inline);
    }

    & > *:last-child {
      padding-inline: var(--padding-inline);
    }
  }
  @media screen and (max-width: 425px) {
    text-align: left;
    & > *:last-child {
      #links {
        justify-content: flex-start;
        margin-inline: -0.3rem;

        & > *:not(:last-child)::after {
          margin-inline: 0.3rem;
        }
      }
    }
  }
}

footer.custom {
  #disclaimer {
    padding-top: 0.7rem;
  }
}
